export const types = {
  LINK: "LINK",
  DIVIDER: "DIVIDER"
};

export const listData = [
  {
    type: types.LINK,
    label: "Rates",
    link: "/rates"
  },
  {
    type: types.LINK,
    label: "Track",
    link: "/track"
  },
  {
    type: types.LINK,
    label: "Execution",
    link: "execution"
  },
  {
    type: types.LINK,
    label: "Billing",
    link: "billing"
  },
  {
    type: types.DIVIDER,
    label: "",
    link: ""
  }
];
